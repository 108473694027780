
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, take } from 'rxjs';
import { AuthService } from './services/auth.service'; // Ensure you have AuthService
import { CommonService } from './services/common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private commonService: CommonService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.commonService.presentLoading();

    const userId = localStorage.getItem('userId');
    const type = localStorage.getItem('type');

    // Check if userId or type is missing
    if (!userId || !type) {
      this.router.navigate(['/login']); // Redirect to login if no userId or type
      return false;
    }
    return new Promise((resolve) => {
      // Subscribe to the userLogin BehaviorSubject
      this.authService.userLogin.subscribe((resp: any) => {
        if (resp) {
          // Data is available from BehaviorSubject
          const moduleName = route.data['moduleName'];
          const hasAccess = resp.modules[moduleName] === true;
        

          if (!hasAccess) {
            this.commonService.showToast('error', 'Access Denied');
            this.router.navigate(['/dashboard']);
            this.commonService.loadingDismiss();
            resolve(false);
          } else {
            console.log('You have access');
            this.commonService.loadingDismiss();
            resolve(true);
          }
        } else {
          // In case the BehaviorSubject does not have data, navigate to login
          this.router.navigate(['/login']);
          this.commonService.loadingDismiss();
          resolve(false);
        }
      });
    });
  }
}

